import React from 'react'

import { ProfileNavBarIcon } from '@/components/GuestProfile/ProfileNavbarIcon'
import { HamburgerMenu } from '@/components/HamburgerMenu/HamburgerMenu'
import {
  useOrderingTypeContext,
  useVenueContext,
} from '@/contexts/VenueOrderContext'
import { CartLandingQuery } from '@/gql/graphql'

import { TableAndDiningTypeSelection } from './TableAndDiningTypeSelection'

export const VenueHeaderInfo = ({
  venue,
  orderingTypePrompt,
  cart,
  cartFetching,
  cartError,
}: {
  venue: any
  orderingTypePrompt: boolean
  cart: CartLandingQuery['getCart']
  cartFetching: boolean
  cartError?: Error | null
}) => {
  const { venueSlug } = useVenueContext()
  const { orderingType } = useOrderingTypeContext()
  return (
    <div className="px-4 pt-4">
      <div className="flex flex-col items-start">
        <div className="mb-1 flex w-full items-center justify-start gap-5">
          <h1 className="line-clamp-3 flex-grow my-heading-lg">{venue.name}</h1>
          <ProfileNavBarIcon
            venueSlug={venueSlug}
            venueId={venue.venueId}
            venueName={venue.venueName}
            className="!h-[36px] !w-[36px] !rounded-full !bg-surface-bright !py-0 !text-foreground"
            isUserFilledIcon
          />
          <HamburgerMenu
            orderingType={orderingType}
            venueSlug={venueSlug}
            className="!h-[36px] !w-[36px] !rounded-full !bg-surface-bright !py-0 !text-foreground"
            isMoreHorizontal
          />
        </div>

        <TableAndDiningTypeSelection
          venue={venue}
          orderingTypePrompt={orderingTypePrompt}
          cart={cart}
          cartFetching={cartFetching}
          cartError={cartError}
        />
      </div>
    </div>
  )
}
