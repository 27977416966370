import { isServer } from '@mr-yum/frontend-core/dist/support/env'
import { isToday, parseISO } from 'date-fns'
import React, { useContext, useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import { useDebounce } from 'react-use'
import { useQuery } from 'urql'

import { ClientOnly } from '@/components/Common/ClientOnly'
import { RewardMembershipCTA } from '@/components/LoyaltyMembership/LoyaltyBanners'
import { ActionBlocks } from '@/components/VenueV2/Blocks/ActionBlocks'
import { ActionBlocksLoader } from '@/components/VenueV2/Blocks/ActionBlocksLoader'
import {
  useOrderingTypeContext,
  useVenueContext,
} from '@/contexts/VenueOrderContext'
import {
  CartLandingQuery,
  CurrentUserDocument,
  ListOpenGroupOrdersForVenueDocument,
  OrderingType,
  VenueLandingAdditionalDocument,
  VenueLandingQuery,
  VenueListMembershipsDocument,
  VenueOrderingWindowsDocument,
} from '@/gql/graphql'
import { routes } from '@/lib/routes'
import { MenuStoreContext } from '@/stores/MenuStore'

import { AddressButton } from './AddressButton'
import { OrderingTypeButton } from './OrderingTypeButton'
import { OrderingWindowButton } from './OrderingWindowButton'
import { TableNumberButton } from './TableNumberButton'
import { useGetNextSheet } from './utils/sheetUtils'
import {
  findSettingsByOrderingType,
  venueToOrderingTypeItems,
} from './utils/venueUtils'

interface Props {
  venue: NonNullable<VenueLandingQuery['guestVenue']>
  orderingTypePrompt: boolean
  cart: CartLandingQuery['getCart']
  cartFetching: boolean
  cartError?: Error | null
}

export const TableAndDiningTypeSelection = ({
  venue,
  cart,
  cartFetching,
  cartError,
}: Props) => {
  const { orderingType } = useOrderingTypeContext()
  const { venueSlug } = useVenueContext()
  const { categorySearch } = useContext(MenuStoreContext)

  const intl = useIntl()

  const [debouncedCategorySearch, setDebouncedCategorySearch] =
    useState(categorySearch)

  useDebounce(
    () => {
      setDebouncedCategorySearch(categorySearch)
    },
    500,
    [categorySearch],
  )

  const [{ data: additionalData, fetching: additionalDataFetching }] = useQuery(
    {
      query: VenueLandingAdditionalDocument,
      pause: isServer || !venue?.id,
      variables: {
        venueSlug,
        orderingType,
      },
      requestPolicy: 'cache-first',
    },
  )

  const [{ data: userData, fetching: userDataFetching }] = useQuery({
    query: CurrentUserDocument,
    pause: isServer,
  })

  const [
    {
      data: currentUserOpenGroupOrders,
      fetching: currentUserGroupOrdersFetching,
      error: currentUserGroupOrdersError,
    },
  ] = useQuery({
    query: ListOpenGroupOrdersForVenueDocument,
    pause: isServer || userDataFetching || !venue?.id,
    variables: {
      venueId: venue?.id ?? '',
    },
    requestPolicy: 'cache-and-network',
  })

  const [{ data: venueMembershipData, fetching: venueMembershipDataFetching }] =
    useQuery({
      query: VenueListMembershipsDocument,
      pause: isServer || !venue?.id || userDataFetching,
      variables: {
        venueId: venue?.id ?? '',
        guestId: userData?.currentUser?.id,
        guestMobile: userData?.currentUser?.mobile,
      },
      requestPolicy: 'cache-and-network',
    })

  const settings = findSettingsByOrderingType({
    venue,
    orderingType,
  })

  const tableIsOff = useMemo(() => {
    if (!cart) {
      return false
    }

    return cart.cartValidationErrorsV2.some(
      (value) => value.type === 'TABLE_NUMBER_ALL_OFF',
    )
  }, [cart])

  const { nonMenuCount, items } = venueToOrderingTypeItems(
    venue,
    orderingType,
    intl,
  )

  const [{ data: venueOrderingWindowsData }] = useQuery({
    query: VenueOrderingWindowsDocument,
    variables: { venueSlug, orderingType },
  })

  const asapSettings =
    venueOrderingWindowsData?.venue?.orderingTypeSettingsV2?.orderingWindow
      ?.asapWindowEnabled

  const { addPaths, editPaths } = useGetNextSheet({
    cart,
    asapEnabled: asapSettings,
    venue,
  })

  const firstOrderingWindow =
    venueOrderingWindowsData?.venue?.orderingTypeSettingsV2?.orderingWindow?.availableOrderingWindows.find(
      (availableOrderingWindow) => availableOrderingWindow.isPast === false,
    )

  const today = firstOrderingWindow?.orderingWindowStartDate
    ? isToday(parseISO(firstOrderingWindow?.orderingWindowStartDate))
    : false

  if (nonMenuCount === 0) {
    return null
  }

  if (cartFetching || !cart) {
    return <div className="mt-2 h-[22px] w-[120px] rounded skeleton" />
  }

  const startDate = cart?.orderingWindowStartDate

  const isVisualMenu = orderingType === OrderingType.Menu
  const isNonVisualMenu = !isVisualMenu && !cartError

  const hasNoPaths = addPaths.length === 0 && editPaths.length === 0
  const hasTableNumberPaths = addPaths.includes('table-number')
  const isAsap = asapSettings && !startDate && today
  const tableNumber =
    cart?.tableNumber && !hasTableNumberPaths ? cart?.tableNumber : null

  return (
    <>
      <div className="flex w-full items-start">
        <div className="flex flex-col items-start justify-between gap-1">
          <OrderingTypeButton venue={venue} items={items} />
          {isNonVisualMenu && (
            <AddressButton
              venue={venue}
              cart={cart}
              isLoading={cartFetching}
              hasNoPaths={hasNoPaths}
            />
          )}
          {isNonVisualMenu && startDate && (
            <OrderingWindowButton
              orderingType={orderingType}
              cart={cart}
              hasNoPaths={hasNoPaths}
              isAsap={isAsap}
            />
          )}
        </div>
        {isNonVisualMenu && (
          <TableNumberButton
            orderingType={orderingType}
            isLoading={cartFetching}
            isTableTurnedOff={tableIsOff}
            cart={cart}
            tableShortText={settings?.tableShortText}
            hasNoPaths={hasNoPaths}
            tableNumber={tableNumber}
          />
        )}
      </div>
      <div className="w-full">
        <ClientOnly>
          {debouncedCategorySearch === '' && (
            <>
              {(additionalDataFetching || currentUserGroupOrdersFetching) && (
                <ActionBlocksLoader />
              )}
              {additionalData &&
                (currentUserOpenGroupOrders || currentUserGroupOrdersError) && (
                  <ActionBlocks
                    venueSlug={venueSlug}
                    venue={venue}
                    orderingType={orderingType}
                    tabs={currentUserOpenGroupOrders?.listBills.items ?? []}
                  />
                )}
            </>
          )}

          {!venueMembershipDataFetching &&
            !userDataFetching &&
            venueMembershipData?.listVenueMemberships?.map((program) => {
              return (
                program?.configuration?.cta?.['link'] &&
                !program.membership.id && (
                  <RewardMembershipCTA
                    key={program.id}
                    venueName={venue.name}
                    program={program}
                    cta="link"
                    href={routes.rewardLinkMembership.toPath!({
                      venueSlug,
                      programId: program.id,
                    })}
                  />
                )
              )
            })}
        </ClientOnly>
      </div>
    </>
  )
}
