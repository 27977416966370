import { observer } from 'mobx-react-lite'
import React from 'react'

import { useEonxEnvContext } from '@/components/EonX/EonxEnv'
import { OrderingType, VenueLandingQuery } from '@/gql/graphql'
import { isLokeOrigin } from '@/hooks/useLoke'

import { ManageTabBlock } from './ManageTabBlock'
import { StartTabBlock } from './StartTabBlock'

interface Props {
  venueSlug: string
  venue: VenueLandingQuery['guestVenue']
  orderingType: OrderingType
  tabs: { id: string }[]
  className?: string
}

export const ActionBlocks = observer(
  ({ venueSlug, venue, orderingType, tabs }: Props) => {
    const { isEonxEnv } = useEonxEnvContext()

    const availableForOrderingType = [
      OrderingType.DineIn,
      OrderingType.Counter,
    ].includes(orderingType)

    const hasTab =
      !!venue?.startTabAvailable &&
      availableForOrderingType &&
      !isLokeOrigin() &&
      !isEonxEnv

    const count = [hasTab].filter(Boolean).length

    if (!count || venue?.isClosed) return null

    return (
      <div className="-mr-4 mt-3 flex gap-3 overflow-x-auto whitespace-nowrap pr-4 scrollbar-hide md:mr-0 md:pr-0 [&>a]:rounded-md [&>a]:py-1">
        {hasTab && tabs.length === 0 && (
          <StartTabBlock venueSlug={venueSlug} count={count} />
        )}

        {hasTab && tabs.length > 0 && (
          <ManageTabBlock
            venueSlug={venueSlug}
            count={count}
            tabId={tabs[0].id}
          />
        )}
      </div>
    )
  },
)

ActionBlocks.displayName = 'ActionBlocks'
