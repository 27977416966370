import { cn } from '@mr-yum/frontend-ui'
import { observer } from 'mobx-react-lite'
import React, { useContext, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useDebounce } from 'react-use'
import { useQuery } from 'urql'

import { NotFound } from '@/components/Common/NotFound'
import { CheckoutButton } from '@/components/MenuV2/CheckoutButton'
import { Image } from '@/components/Shared/Image'
import { VenueCategories } from '@/components/VenueV2/VenueCategories'
import {
  useOrderingTypeContext,
  useVenueContext,
} from '@/contexts/VenueOrderContext'
import { OrderingType, VenueLandingDocument } from '@/gql/graphql'
import { useCartLanding } from '@/hooks/useCart'
import { MenuStoreContext } from '@/stores/MenuStore'

import { CategorySearchV2 } from '../Venue/MvoSearch/CategorySearch'
import { DisplayCategorySearchResults } from '../Venue/MvoSearch/DisplayCategorySearchResults'
import { VenueCTAButton } from './Blocks/VenueCTAButton'
import PersonalisedExperience from './PersonalisedExperience'
import { Sheets } from './Sheets/Sheets'
import { findSettingsByOrderingType } from './utils/venueUtils'
import { VenueHeaderInfo } from './VenueHeaderInfo'
import { VenueLogo } from './VenueLogo'
import { VenueMessage } from './VenueMessage'

export type VenuePageProps = {
  orderingTypePrompt?: boolean
  showThePassUpdateRequiredNotice?: boolean
}

export const VenuePageV2 = observer(
  ({ orderingTypePrompt = false }: VenuePageProps) => {
    const { orderingType } = useOrderingTypeContext()
    const { venueSlug } = useVenueContext()
    const { categorySearch } = useContext(MenuStoreContext)

    const intl = useIntl()
    const [debouncedCategorySearch, setDebouncedCategorySearch] =
      useState(categorySearch)

    const [{ data: venueData, error: venueError, fetching: venueFetching }] =
      useQuery({
        query: VenueLandingDocument,
        variables: {
          venueSlug,
        },
      })

    const venue = venueData?.guestVenue

    const {
      cart,
      stale: cartStale,
      fetching: cartFetching,
      error: cartError,
    } = useCartLanding({
      venueSlug,
      orderingType,
      pause: !venue,
      requestPolicy: 'cache-and-network',
    })

    const settings = findSettingsByOrderingType({
      venue,
      orderingType,
    })

    const landingText = settings?.landingText
    const mobileBanner = venue?.mobileBanner ?? venue?.banner
    const mvoEnabled = venue?.multiVendorEnabled
    const venueCTAText = venue?.landingCallToActionText?.substring(0, 40)
    const isVisualMenu = orderingType === OrderingType.Menu

    useDebounce(
      () => {
        setDebouncedCategorySearch(categorySearch)
      },
      500,
      [categorySearch],
    )

    if (venueError || (!venueFetching && !venue)) {
      return (
        <>
          <NotFound
            message={intl.formatMessage(
              {
                defaultMessage: 'Venue {venueSlug} not found',
                id: '2R567s',
              },
              { venueSlug },
            )}
            error={venueError}
          />
        </>
      )
    }

    if (!venue) {
      return null
    }
    return (
      <>
        <div className="min-h-screen bg-surface-desktop">
          <div className="m-auto flex min-h-screen max-w-mobile flex-col bg-surface shadow-xl">
            <div className="fixed top-0 flex aspect-[2.86] w-full max-w-mobile items-center justify-center bg-surface">
              {mobileBanner && (
                <Image
                  image={mobileBanner}
                  className="!inset-0"
                  alt=""
                  layout="fill"
                  objectFit="cover"
                  priority
                />
              )}

              <VenueLogo venueLogo={venue.logo} />
            </div>
            <div
              className={cn(
                'z-10 flex-grow pb-10 pt-[calc((100%/2.86)-24px)]',
                {
                  'pb-20':
                    venue.landingCallToActionText &&
                    venue.landingCallToActionUrl,
                },
              )}
            >
              <div className="flex flex-col space-y-2 divide-y divide-subtle rounded-t-3xl bg-surface pt-2 shadow-[0px_-20px_25px_-5px_rgba(0,0,0,0.1)]">
                <VenueHeaderInfo
                  venue={venue}
                  orderingTypePrompt={orderingTypePrompt}
                  cart={cart}
                  cartFetching={cartFetching}
                  cartError={cartError}
                />
                <PersonalisedExperience
                  venue={venue}
                  cart={cart}
                  cartFetching={cartFetching}
                />

                {mvoEnabled && <CategorySearchV2 />}
                <div className="space-y-4 px-4">
                  {(isVisualMenu || landingText) && (
                    <div className="pt-4">
                      {isVisualMenu && (
                        <h2 className="my-heading-md">
                          <FormattedMessage
                            defaultMessage="View menu"
                            id="ZAUJRl"
                          />
                        </h2>
                      )}

                      {landingText && <VenueMessage message={landingText} />}
                    </div>
                  )}
                  {!debouncedCategorySearch && (
                    <h3
                      className={cn(
                        'mb-4 my-heading-sm',
                        !(isVisualMenu || landingText) && 'pt-4',
                      )}
                    >
                      {mvoEnabled ? (
                        <FormattedMessage
                          defaultMessage="Explore vendors"
                          id="HclCF/"
                        />
                      ) : (
                        <FormattedMessage
                          defaultMessage="Browse our menu"
                          id="wPwRR3"
                        />
                      )}
                    </h3>
                  )}
                  <DisplayCategorySearchResults
                    cart={cart}
                    debouncedCategorySearch={debouncedCategorySearch}
                  />
                  <VenueCategories
                    multiVendorEnabled={venue.multiVendorEnabled}
                    showCategoryListViewOnLandingPage={
                      venue.showCategoryListViewOnLandingPage
                    }
                    cart={cart}
                    cartStale={cartStale}
                    cartFetching={cartFetching}
                    orderingTypes={venue.orderingTypes}
                    debouncedCategorySearch={debouncedCategorySearch}
                  />
                  <VenueCTAButton
                    text={venueCTAText}
                    url={venue.landingCallToActionUrl}
                  />
                </div>
              </div>
            </div>
          </div>
          <Sheets
            venue={venue}
            cart={cart}
            cartFetching={cartFetching}
            cartStale={cartStale}
            cartError={cartError}
            orderingTypePrompt={orderingTypePrompt}
          />
        </div>
        <div className="fixed inset-x-0 bottom-4 z-50 mx-auto w-fit">
          <CheckoutButton />
        </div>
      </>
    )
  },
)
